/* .item must be first */
.item {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #808e9b;
}

.active {
  border: solid 1px #808e9b;
  border-radius: 40px;
  color: #808e9b;
}

.break-me {
}

.next {
  border-left: solid 1px #808e9b;
  font-size: 14px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}

.pagination {
  align-items: center;
  background-color: orange;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: "100%";
}

.pagination-page {
  font-weight: 700;
}

.previous {
  border-right: solid 1px #808e9b;
  font-size: 14px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}
.btnSearch {
  border-width: 0px;
  background-color: transparent;
}
